const translations = {
  fr: {
    login: 'Se connecter',
    login_title: 'Connexion',
    logout: 'Se déconnecter',
    account: 'Compte',
    forgot_password: 'Mot de passe oublié ?',
    bad_password: 'Mauvais mot de passe',
    password: 'Mot de passe',
    email: 'Email',
    home: 'Accueil',
    submit: 'Soumettre',
    generate_new_password: 'Réinitialiser un nouveau mot de passe',
    email_required: "L'email est requis",
    password_required: 'Le mot de passe est requis',
    email_must_be_valid: "L'email doit être valide",
    service_unavailable: 'Service indisponible',
    email_doesnt_exist: "Cet email n'existe pas",
    email_sent_to_reinitialize_password:
      'Un email pour réinitiliser votre mot de passe a été envoyé',
    password_greater_than:
      'Le mot de passe doit contenir au moins 7 caractères',
    password_should_be_identical: 'Les mots de passe doivent être identiques',
    password_confirmation: 'Confirmation du mot de passe',
    password_changed: 'Votre mot de passe a bien été modifié',
    invalid_token:
      'Token invalide, veuillez faire une nouvelle demande de génération de mot de passe',
    new_password_generation_request:
      'Nouvelle demande de génération de mot de passe',
    search_for_a_bond: 'Rechercher un cautionnement',
    no_results_for: 'Pas de résultats pour',
    nb_bond: 'Nr.Acte',
    bond_date: 'Date acte',
    amount_eur: 'Montant',
    balance_eur: 'Solde',
    works_wording: 'Libellé des travaux',
    releases: 'Libérations',
    actions: 'Actions',
    companies: 'Sociétés',
    applications: 'Demandes',
    actes: 'Actes',
    applications_and_actes: 'Actes & demandes',
    balance_gt_0: 'Actes avec solde > 0',
    balance_equal_0: 'Actes avec solde = 0',
    every_type_of_balance: "Tout type d'acte",
    all: 'Tout',
    rows_per_page: 'Lignes par page',
    of: 'sur',
    no_data_text: 'Aucune donnée trouvée',
    bond_not_found: 'Impossible de récupérer ce cautionnement',
    company: 'Société',
    date_acte: "Date de l'acte",
    reference: 'Numéro de demande',
    nr_caut: 'Numéro de cautionnement',
    nr_acte: "Numéro d'acte",
    texte_special: 'Texte spécial',
    montant_devise: 'Mt. devise',
    montant_initial: 'Initial',
    montant_engage: 'Engagé',
    montant_lettre: 'Montant en toute lettre',
    montant_droit_garde: 'Montant de droit de garde',
    montant_travaux: 'Mt travaux HTVA',
    langue: 'Langue',
    langue_acte: "Langue de l'acte",
    modalite_lib: 'Modalités de libération',
    date_saisie_dec: 'Date de création de la demande',
    date_liberation_totale: 'Date de libération totale',
    date_accorde: 'Date à laquelle cela a été accordé',
    p_redevance: 'Pourcentage de redevance',
    status_facturation: 'Statut de facturation',
    reference_externe: 'Référence externe',
    export_flag: "Statut d'exportation",
    bond_data: 'Détails du cautionnement',
    complete_description: 'Description du cautionnement',
    work_nature: 'Nature des travaux',
    beneficiaire: 'Bénéficiaire',
    liberations: 'Historique des libérations',
    edoc: 'E-Docs',
    back: 'Retour',
    bond_nr: 'Cautionnement',
    new: 'Nouveau',
    add_new_bond: 'Introduire une nouvelle demande de cautionnement',
    validate: 'Valider',
    devise_needed: 'La devise est requise',
    montant_devise_required: 'Le montant devisé est requis',
    montant_gt_0: 'Le montant devisé doit être positif',
    montant_too_high: 'Le montant devisé est trop élevé',
    montant_travaux_required: 'Le montant des travaux est requis',
    montant_travaux_gt_0: 'Le montant des travaux doit être positif',
    montant_travaux_too_high: 'Le montant des travaux est trop élevé',
    montant_travaux_tva: 'TTC',
    langue_needed: 'La langue est requise',
    modalite_lib_needed: 'La modalité de libération est requise',
    devise: 'Devise',
    lib_int: 'Description courte du cautionnement',
    lib_int_required: 'La description courte du cautionnement est requise',
    lib_int_too_long: 'La description courte du cautionnement est trop longue',
    reference_externe_required: 'La référence externe est requise',
    reference_externe_too_long: 'La référence externe est trop longue',
    linked_document: 'Document lié',
    linked_document_required: 'Le document lié est requis',
    bond_sucessfully_created:
      'La demande de cautionnement a bien été introduite. Numéro de votre demande: ',
    an_error_occured: "Une erreur s'est produite",
    bond_sucessfully_updated:
      'La demande de cautionnement a bien été mise à jour',
    update_new_bond: 'Modifier un cautionnement',
    update_linked_document: 'Modifier le document lié',
    cancel: 'Annuler',
    no_company_assigned: "Votre compte n'est assigné à aucune société.",
    all_companies: 'Toutes les sociétés',
    status: 'Statut',
    process_bond: 'Traitement de la demande de cautionnement',
    username: "Nom d'utilisateur",
    role: 'Rôle',
    collaborator_management: 'Gestion des collaborateurs',
    ROLE_USER: 'Utilisateur',
    ROLE_ADMIN: 'Administrateur',
    ROLE_USER_READONLY: 'Consultation',
    ROLE_SUPERADMIN: 'Admin Fideris',
    active: 'Actif',
    inactive: 'Inactif',
    add_new_user: 'Ajouter un nouvel utilisateur',
    user_sucessfully_created: "L'utilisateur a bien été créé",
    username_required: "Le nom d'utilisateur est requis",
    username_too_long: "Le nom d'utilisateur est trop long",
    role_needed: 'Le role est requis',
    status_needed: 'Le statut est requis',
    update_new_user: 'Mettre à jour un utilisateur',
    password_generation_sent: 'Email de génération de mot de passe envoyé',
    confirm_user_delete: 'Voulez-vous vraiment supprimer cet utilisateur ?',
    tooltip_action_pdfform: 'Valider le cautionnement',
    tooltip_action_resume: 'Consulter',
    tooltip_action_edit: 'Modifier',
    tooltip_action_pdf: 'Créer un PDF',
    tooltip_action_excell: 'Créer un Excell',
    tooltip_action_print: "Lancer l'impression",
    user_sucessfully_updated: "L'utilisateur a bien été mis à jour",
    user_sucessfully_deleted: "L'utilisateur a bien été supprimé",
    companies_list: 'Liste des sociétés',
    companies_management: 'Gestion des sociétés',
    name: 'Nom',
    vat_number: 'Numéro de TVA',
    is_momentary: 'Société momentanée ?',
    vat_required: 'Le numéro de TVA est requis',
    vat_too_long: 'Le numéro de TVA est trop long',
    vat_too_short: 'Le numéro de TVA est trop court',
    bank_account_required: 'Le numéro de compte bancaire est requis',
    bank_account_too_long: 'Le numéro de compte bancaire est trop long',
    bank_account_too_short: 'Le numéro de compte bancaire est trop court',
    add_new_company: 'Ajouter une société',
    bank_account: 'Compte bancaire',
    name_required: 'Le nom est requis',
    name_too_long: 'Le nom est trop long',
    name_too_short: 'Le nom est trop court',
    company_sucessfully_created: 'La société a bien été ajoutée',
    company_sucessfully_updated: 'La société a bien été mise à jour',
    company_sucessfully_deleted: 'La société a bien été supprimée',
    confirm_company_delete: 'Voulez-vous vraiment supprimer cette société ?',
    update_new_company: 'Mettre à jour une société',
    make_liberation: 'Effectuer une libération',
    amount: 'Montant',
    date: 'Date',
    date_required: 'La date est requise',
    amount_should_not_be_greater_than_solde:
      'Le montant à libérer ne doit pas être supérieur au solde',
    montant_required: 'Le montant est requis',
    montant_greater_than_0: 'Le montant doit être supérieur à 0',
    liberation_successfully_added:
      'La libération a été correctement introduite',
    transform_in_acte: 'Transformer en acte',
    request_became_acte: 'La demande de cautionnement est devenue un acte',
    update: 'Modifier',
    company_needed: 'La société est requise',
    bond_request: 'Demande de cautionnement',
    contact_list: 'Liste des contacts',
    type: 'Type',
    value: 'Valeur',
    comment: 'Commentaire',
    new_contact: 'Ajouter un contact',
    tel: 'Numéro de téléphone',
    fax: 'Numéro de fax',
    trust: 'Trust',
    type_required: 'Le type est requis',
    value_required: 'La valeur est requise',
    value_too_long: 'La valeur est trop longue',
    comment_too_long: 'Le commentaire est trop long',
    delete_contact: 'Supprimer le contact',
    edit_contact: 'Modifier le contact',
    confirm_contact_delete: 'Voulez-vous vraiment supprimer ce contact ?',
    contact_sucessfully_created: 'Le contact a bien été ajouté',
    contact_sucessfully_updated: 'Le contact a bien été mis à jour',
    contact_sucessfully_deleted: 'Le contact a bien été supprimé',
    delete_address: "Supprimer l'adresse",
    edit_address: "Modifier l'adresse",
    confirm_address_delete: 'Voulez-vous vraiment supprimer cette adresse ?',
    address_sucessfully_created: "L'adresse a bien été ajoutée",
    address_sucessfully_updated: "L'adresse a bien été mise à jour",
    address_sucessfully_deleted: "L'adresse a bien été supprimée",
    street: 'Rue',
    post_code: 'Code postal',
    number: 'Numéro de rue',
    city: 'Ville',
    other: 'Autre',
    country: 'Pays',
    addresses_list: 'Liste des adresses',
    street_required: 'La rue est requise',
    street_too_short: 'La rue est trop courte',
    street_too_long: 'La rue est trop longue',
    post_code_required: 'Le code postal est requis',
    post_code_too_short: 'Le code postal est trop court',
    post_code_too_long: 'Le code postal est trop long',
    number_required: 'Le numéro de rue est requis',
    number_too_long: 'Le numéro de rue est trop long',
    number_too_short: 'Le numéro de rue est trop court',
    city_required: 'La ville est requise',
    city_too_short: 'La ville est trop courte',
    city_too_long: 'La ville est trop longue',
    other_too_long: 'Le champ autre est trop long',
    other_too_short: 'Le champ autre est trop court',
    country_required: 'Le pays est requis',
    new_address: 'Ajouter une adresse',
    tooltip_action_edit_society: "Accès à l'édition de l'entreprise",
    tooltip_action_delete_society: "Supprimer l'entreprise",
    tab_company_adresse: 'Adresses',
    tab_company_contact: 'Contacts',
    tab_company_info: 'Informations',
    BE: 'Belgique',
    FR: 'France',
    NL: 'Pays-Bas',
    DE: 'Allemagne',
    GB: 'Royaume-Uni',
    LU: 'Luxembourg',
    new_beneficiaire: 'Ajouter un bénéficiaire',
    delete_beneficiaire: 'Supprimer le bénéficiaire',
    edit_beneficiaire: 'Modifier le bénéficiaire',
    confirm_beneficiaire_delete:
      'Voulez-vous vraiment supprimer ce bénéficiaire ?',
    beneficiaire_sucessfully_created: 'Le bénéficiaire a bien été ajouté',
    beneficiaire_sucessfully_updated: 'Le bénéficiaire a bien été mis à jour',
    beneficiaire_sucessfully_deleted: 'Le bénéficiaire a bien été supprimé',
    beneficiaire_required: 'Le bénéficiaire est requis',
    edocs_management: 'DOCBOX',
    bond: 'Demande',
    search_for_an_edoc: 'Rechercher un e-doc',
    add_edoc: 'Ajouter un e-doc',
    edoc_type: "Type d'e-doc",
    is_a_private_bond: 'Est-ce un cautionnement privé ?',
    is_complementary: 'Est-ce un cautionnement complémentaire ?',
    bond_type: 'Type de cautionnement',
    common_public: 'Public ordinaire',
    loi_breyne: 'Public loi Breyne',
    common_private: 'Privé ordinaire',
    special_private: 'Privé texte spécial',
    manage_liberations: 'Gérer les libérations',
    delete_last_liberation: 'Annuler la dernière libération',
    bond_management: 'Cautionnements',
    tooltip_create_complementary:
      'Créer un cautionnement complémentaire à ce cautionnement',
    create_complementary_bond: "Création d'un cautionnement complémentaire",
    request: 'Demande',
    liberation: 'Libération',
    acte: 'Acte',
    at_least_1_companies_needed: 'Il faut au moins sélectionner 1 société',
    edoc_successfully_added: 'Edoc ajouté avec succès.',
    modalite_lib_required: 'La modalité de libération est requise',
    modalite_lib_too_short: 'La modalité de libération est trop courte',
    modalite_lib_too_long: 'La modalité de libération est trop longue',
    please_link_contract: 'Veuillez fournir le contrat en PDF',
    amount_with_modalite_lib_invalid:
      'La modalité de libération de ce cautionnement ne coincide pas avec le montant introduit',
    bond_required: 'Demande obligatoire',
    bonds_management: 'Gestion des cautionnements',
    title_liberation: 'Gestion des libérations',
    your_ref_ext: 'Votre référence externe du dossier',
    doc001: 'Contrat',
    doc002: 'Texte spécial',
    doc003: 'Société momentanée',
    doc200: 'Acte',
    doc201: 'Acte',
    doc202: 'Acte',
    doc900: 'Facture',
    doc901: 'Facture',
    doc902: 'Facture',
    doc300: 'Libération',
    doc301: 'Libération',
    doc302: 'Libération',
    type_needed: 'Le type est requis',
    for_momentary: "S'agit-il d'une demande pour une société momentanée ?",
    company_form: 'Veuillez fournir le formulaire de société momentanée en PDF',
    company_form_required: 'Le formulaire de société momentanée est requis',
    texte_special_doc: 'Veuillez fournir le document de texte spécial',
    texte_special_doc_required: 'Le document de texte spécial est requis',
    current_company_form: 'Document de société momentanée actuel',
    current_texte_special_form: 'Document de texte spécial actuel',
    update_that_document: 'Choisir un nouveau document',
    linked_documents: 'Document liés',
    add_line: 'Ajouter une ligne',
    success_multiple_liberations: 'Les libérations ont été effectuées',
    before: 'Avant',
    after: 'Après',
    amount_to_liberate: 'Montant à libérer',
    nature_travaux_required: 'La nature des travaux est requise',
    nature_travaux_too_long: 'La nature des travaux est trop longue',
    all_in_one: 'Libération totale (100%)',
    partial_50_50: 'Libération partielle (50% / 50%)',
    search_for_a_company: 'Rechercher une société',
    number_: 'Nombre',
    total: 'Total',
    reset: 'Réinitialiser',
    manage_beneficiaires: 'Gérer les bénéficiaires',
    private: 'Privé',
    public: 'Public',
    list_number: 'Liste',
    date_not_in_trimester: "La date n'est pas dans le trimestre courant",
    vat_number_invalid: "Le numéro de TVA n'est pas valide",
    bank_account_invalid:
      "Le numéro de compte n'est pas valide pour la Belgique",
    new_momentary_company: "Création d'une société momentanée",
    confirm_edoc_delete: 'Voulez vous vraiment supprimer ce document?',
    nr_acte_required: "Le numéro d'acte est requis",
    nr_acte_too_long: "Le numéro d'acte est trop long",
    formalize_acte: "Officialiser l'acte",
    formalize: 'Officialiser',
    nr_acte_not_equal_caut:
      "Le numéro d'acte n'est pas égal au numéro de cautionnement",
    this_is_complementary: 'Ceci est un cautionnement complémentaire',
    parent_bond: 'Cautionnement parent',
    prefill_nature_travaux: 'Pré-remplir nature travaux',
    caut_not_found: "Le cautionnement n'a pas été trouvé.",
    no_benef_found: 'Pas de bénéficiaire lié',
    filter_by_name: 'Filtrer par nom',
    siege_social: 'Siège social',
    correspondance: 'Correspondance',
    info_required: 'Information requise',
    nr_amo: "Numéro d'associé",
    nr_amo_will_be_generated: "Le numéro d'associé sera généré automatiquement",
    search_in_pdf: 'Rechercher dans le PDF',
    pdf_search_results_for: 'Résultats de la recherche dans le PDF pour ',
    no_pdf_search_results_for: 'Pas de résultats dans le PDF pour ',
    initial: 'Initial',
    solde: 'Solde',
    ref_ext: 'Réf.Ext.',
    tooltip_open_request: 'Demande modifiable',
    tooltip_closed_request: 'Demande verrouillée',
    num: 'Num.',
    beneficiaires: 'Bénéficiaires',
    nr_id: "Numéro de registre national ou d'entreprise",
    nr_id_required: "Numéro de registre national ou d'entreprise requis",
    nr_id_invalid: "Numéro de registre national ou d'entreprise invalide",
    nr_id_mandatory: "Numéro de registre national ou d'entreprise obligatoire",
    file_here: "Les fichiers que vous mettez en ligne s'afficheront ici",
    tooltip_action_dl_pdf: 'Télécharger les documents liés à cette demande',
    bond_id_since_when_you_want_documents:
      'Numéro de la demande à partir de laquelle vous souhaitez récuperer les documents',
    download_document: 'Télécharger les documents',
    limit_id_for_edoc_dl: 'Numero de demande maximum'
  },
  nl: {
    login: 'Login',
    login_title: 'Aanmelden',
    logout: 'Afmelden',
    account: 'Account',
    forgot_password: 'Wachtwoord vergeten',
    bad_password: 'Fout wachtwoord',
    password: 'wachtwoord',
    email: 'e-mail',
    home: 'Home',
    submit: 'Voorleggen',
    generate_new_password: 'Nieuw wachtwoordopnieuw instellen',
    email_required: 'e-mail noodzakelijk',
    password_required: 'Wachtwoord noodzakelijk',
    email_must_be_valid: 'e-mail moet geldig zijn',
    service_unavailable: 'Is niet beschikbaar',
    email_doesnt_exist: 'e-mail bestaat niet meer',
    email_sent_to_reinitialize_password:
      'Een bevestigingsmail met uw nieuw wachtwoord werd u gestuurd',
    password_greater_than: 'Wachtwoord moet minimum 7 karakters bevatten',
    password_should_be_identical: 'Identiek wachtwoord',
    password_confirmation: 'Bevestiging van uw wachtwoord',
    password_changed: 'Uw wachtwoord werd aangepast',
    invalid_token:
      'Invalid token. aanvraag voor het genereren van een nieuw wachtwoord',
    new_password_generation_request: 'aanvraag van wachtwoord',
    search_for_a_bond: 'Een borgstelling opzoeken',
    no_results_for: 'Geen resultaat voor',
    nb_bond: 'Akte nr',
    bond_date: 'Datum Akte',
    amount_eur: 'Bedrag €',
    balance_eur: 'Saldo €',
    works_wording: 'Beschrijving van de werken',
    releases: 'Vrijgaven',
    actions: 'Acties',
    companies: 'Ondernemingen',
    applications: 'Aanvragen',
    actes: 'Akten',
    applications_and_actes: 'Akten & aanvragen',
    balance_gt_0: 'Saldo > 0',
    balance_equal_0: 'Saldo = 0',
    every_type_of_balance: 'Allen',
    all: 'Alle',
    rows_per_page: 'Lijnen per blad',
    of: 'Op',
    no_data_text: 'Geen data gevonden',
    bond_not_found: 'Onmogelijk om deze borgstelling terug te halen',
    company: 'Onderneming',
    date_acte: 'Datum akte',
    reference: 'Aanvraagnummer',
    nr_caut: 'Borgstellingsnummer',
    nr_acte: 'Nummer borgstellingsakte',
    texte_special: 'Specifieke tekst',
    montant_devise: 'Valuta bedrag',
    montant_initial: 'Afgerond bedrag',
    montant_engage: 'Vastgelegd bedrag',
    montant_lettre: 'Bedrag voluit in letters',
    montant_droit_garde: 'Bewaargeld',
    montant_travaux: 'Bedrag van de werken',
    langue: 'Taal',
    langue_acte: 'Taal van de akte',
    modalite_lib: 'Vrijgave modaliteiten',
    date_saisie_dec: 'Aanvraagdatum',
    date_liberation_totale: 'Datum volledige vrijgave',
    date_accorde: 'Datum van toewijzing',
    p_redevance: 'Percentage van de retributie',
    status_facturation: 'Facturatie status',
    reference_externe: 'externe referentie',
    export_flag: 'Export status',
    bond_data: 'Detail van de borgstelling',
    complete_description: 'Beschrijving van de borgstelling',
    work_nature: 'Aard van de werken',
    beneficiaire: 'Begunstigde',
    beneficiaires: 'Begunstigden',
    liberations: 'Historiek van de vrijgaven',
    edoc: 'E-Docs',
    back: 'Terug',
    bond_nr: 'Borgstelling',
    new: 'Nieuw',
    add_new_bond: 'Een nieuwe aanvraag indienen',
    validate: 'Valideren',
    devise_needed: 'Valuta is noodzakelijk',
    montant_devise_required: 'Valuta bedrag is noodzakelijk',
    montant_gt_0: 'Valuta bedrag moet positief zijn',
    montant_too_high: 'Valuta bedrag is te hoog',
    montant_travaux_required: 'Bedrag van de werken is noodzakelijk',
    montant_travaux_gt_0: 'Bedrag van de werken moet positief zijn > 0',
    montant_travaux_too_high: 'Bedrag van de werken is te hoog',
    montant_travaux_tva: 'Bedrag inclusief BTW',
    langue_needed: 'Taal noodzakelijk',
    modalite_lib_needed: 'Vrijgave modaliteiten noodzakelijk',
    devise: 'Valuta',
    lib_int: 'Korte beschrijving van de borgstelling',
    lib_int_required: 'Korte beschrijving van de borgstelling is noodzakelijk',
    lib_int_too_long: 'Beschrijving van de borgtelling te lang',
    reference_externe_required: 'De externe verwijzing is noodzakelijk',
    reference_externe_too_long: 'De externe verwijzing is te lang',
    linked_document: 'Link document',
    linked_document_required: 'Link met document is noodzakelijk',
    bond_sucessfully_created:
      'Borgstellingsaanvraag is ingediend. Borgstellingsaanvraagnummer: ',
    an_error_occured: 'Een fout heeft zich voorgedaan',
    bond_sucessfully_updated: 'Borgstelling aanvraag is bijgewerkt',
    update_new_bond: 'Wijzigen een borgstelling',
    update_linked_document: 'wijzigen van het gerelateerd document',
    cancel: 'Annuleren',
    no_company_assigned: 'Uw account is niet toegewezen aan een onderneming',
    all_companies: 'Alle ondernemingen',
    status: 'Statuut',
    process_bond: 'Behandeling van de borgstellingsaanvraag',
    username: 'Naam van gebruiker',
    role: 'Gebruiker naam',
    collaborator_management: 'Beleid van de medewerkers',
    ROLE_USER: 'Gebruiker',
    ROLE_ADMIN: 'Bestuurder',
    ROLE_USER_READONLY: 'Raadpleging',
    ROLE_SUPERADMIN: 'Admin Fideris',
    active: 'Actief',
    inactive: 'Inactief',
    add_new_user: 'Een nieuwe gebruiker toevoegen',
    user_sucessfully_created: 'De gebruiker is aangemaakt',
    username_required: 'De naam van gebruiker is noodzakelijk',
    username_too_long: 'De naam van gebruiker is te lang',
    role_needed: 'Role is noodzakelijk',
    status_needed: 'Statuut is noodzakelijk',
    update_new_user: 'Gebruiker bijwerken',
    password_generation_sent: 'Email voor genereren wachtwoord is vestuurd',
    confirm_user_delete: 'Wenst u deze gebruiker schrappen ?',
    tooltip_action_pdfform: 'Valideer de borgstelling',
    tooltip_action_resume: 'Consulteren',
    tooltip_action_edit: 'Bewerken',
    tooltip_action_pdf: 'aanmaken van een PDF',
    tooltip_action_excell: 'Aanmaken van een Excell',
    tooltip_action_print: 'Afdrukken',
    user_sucessfully_updated: 'Gebruiker werd bijgewerkt',
    user_sucessfully_deleted: 'De gebruiker werd verwijdert',
    companies_list: 'Lijst van de ondernemingen',
    companies_management: 'Beheer van de ondernemingen',
    name: 'Naam',
    vat_number: 'BTW nummer',
    is_momentary: 'Tijdelijke vereniging ?',
    vat_required: 'BTW nummer is noodzakelijk',
    vat_too_long: 'BTW nummer is te lang',
    vat_too_short: 'BTW nummer  is te kort',
    bank_account_required: 'Bankrekeningnummer is noodzakelijk',
    bank_account_too_long: 'Bankrekeningnummer is te lang',
    bank_account_too_short: 'Bankrekeningnummer is te kort',
    add_new_company: 'Een onderneming toevoegen',
    bank_account: 'Bankrekening',
    name_required: 'Naam is noodzakelijk',
    name_too_long: 'Naam is te lang',
    name_too_short: 'Naam is te kort',
    company_sucessfully_created: 'Onderneming werd toegevoegd',
    company_sucessfully_updated: 'Onderneming werd bijgewerkt',
    company_sucessfully_deleted: 'Onderneming werd gewist',
    confirm_company_delete: 'Wenst u deze onderneming te wissen ?',
    update_new_company: 'Bijwerken van de onderneming',
    make_liberation: 'Een vrijgave uitvoeren',
    amount: 'Bedrag',
    date: 'Datum',
    date_required: 'Datum is noodzakelijk',
    amount_should_not_be_greater_than_solde:
      'Bedrag van de vrijgave mat niet hoger zijn dan het saldo',
    montant_required: 'Bedrag is noodzakelijk',
    montant_greater_than_0: 'Bedrag moet hoger zijn > 0',
    liberation_successfully_added: 'Vrijgave is correct ingegeven',
    transform_in_acte: 'Omgevormd in akte',
    request_became_acte: 'Borgstellingsaanvraag is een akte geworden',
    update: 'Wijzigen',
    company_needed: 'Onderneming is noodzakelijk',
    bond_request: 'Borgstellingsaanvraag',
    contact_list: 'Contactenlijst',
    type: 'Soort',
    value: 'Waarde',
    comment: 'Opmerkingen',
    new_contact: 'Een contact toevoegen',
    tel: 'Tel Nr.',
    fax: 'Fax Nr.',
    trust: 'Trust',
    type_required: 'Soort is noodzakelijk',
    value_required: 'De waarde is noodzakelijk',
    value_too_long: 'De waarde is te lang',
    comment_too_long: 'De commentaar is te lang',
    delete_contact: 'Het contact wissen',
    edit_contact: 'Het contact wijzigen',
    confirm_contact_delete: 'Wenst u dit contact wissen?',
    contact_sucessfully_created: 'Contact werd toegevoegd',
    contact_sucessfully_updated: 'Contact werd bijgewekrt',
    contact_sucessfully_deleted: 'Het contact werd gewist',
    delete_address: 'Het adres wissen',
    edit_address: 'Wijziging adres',
    confirm_address_delete: 'Wenst u dit adres te wissen',
    address_sucessfully_created: 'Adres werd toegevoegd',
    address_sucessfully_updated: 'Adres werd bijgewekrt',
    address_sucessfully_deleted: 'Het adres werd gewist',
    street: 'Straat',
    post_code: 'Postcode',
    number: 'Straat nummer',
    city: 'Stad',
    other: 'Andere',
    country: 'Land',
    addresses_list: 'Lijst van de adressen',
    street_required: 'Straat is noodzakelijk',
    street_too_short: 'Straat is te kort',
    street_too_long: 'Straat is te lang',
    post_code_required: 'Postcode is noodzakelijk',
    post_code_too_short: 'Postcode is te kort',
    post_code_too_long: 'Postcode is te lang',
    number_required: 'Straatnummer is noodzakelijk',
    number_too_long: 'Straatnummer is te lang',
    number_too_short: 'Straatnummer is te kort',
    city_required: 'Stad is noodzakelijk',
    city_too_short: 'Stad is te kort',
    city_too_long: 'Stad is te lang',
    other_too_long: 'Het veld is te lang',
    other_too_short: 'Het veld is te kort',
    country_required: 'Land is noodzakelijk',
    new_address: 'Een adres toevoegen',
    tooltip_action_edit_society: 'Toegang tot de onderneming afdruk',
    tooltip_action_delete_society: 'Monderneming wissen',
    tab_company_adresse: 'Adressen',
    tab_company_contact: 'Contacten',
    tab_company_info: 'Inlichtingen',
    BE: 'Belgie',
    FR: 'Frankrijk',
    NL: 'Nederland',
    DE: 'Duitsland',
    GB: 'Groot-Britannie',
    LU: 'Luxemburg',
    new_beneficiaire: 'Een begunstigde toevoegen',
    delete_beneficiaire: 'De begunstigde wissen',
    edit_beneficiaire: 'De begunstigde wijzigen',
    confirm_beneficiaire_delete: 'Wenst u de begunstigde te wissen ?',
    beneficiaire_sucessfully_created: 'De begunstigdewerd toegevoegd',
    beneficiaire_sucessfully_updated: 'De begunstigde werd gewijzigd',
    beneficiaire_sucessfully_deleted: 'De begunstigde werd afgeschaft',
    beneficiaire_required: 'De begunstigde is noodzakelijk',
    edocs_management: 'DOCBOX',
    bond: 'Aanvraag',
    search_for_an_edoc: 'Zoeken naar e-doc',
    add_edoc: 'e-doc bijvoegen',
    edoc_type: 'Soort e-doc',
    is_a_private_bond: 'Is het een  privé borgstelling ?',
    is_complementary: 'Is het een  bijkomende borgstelling ?',
    bond_type: 'Soort borgstelling',
    common_public: 'Standaard publiek',
    loi_breyne: 'Wet Breyne',
    common_private: 'Standaard privé',
    special_private: 'Specifieke tekst privé',
    manage_liberations: 'Vrijgaven beheren',
    delete_last_liberation: 'Annuleren van de laatste vrijgave',
    bond_management: 'Het beheer van de borgstellingen',
    tooltip_create_complementary:
      'Opmaken van een bijkomende borgstelling bij deze borgstelling',
    create_complementary_bond: 'Opmaken van een bijkomende borgstelling',
    request: 'Aanvraag',
    liberation: 'Vrijgave',
    acte: 'Akte',
    at_least_1_companies_needed: 'U moet minstens 1 onderneming uitkiezen',
    edoc_successfully_added: 'e-doc met succes toevoegd',
    modalite_lib_required: 'Vrijgave modaliteit is noodzakelijk',
    modalite_lib_too_short: 'Vrijgave modaliteit is te kort',
    modalite_lib_too_long: 'Vrijgave modaliteit is te lang',
    please_link_contract: 'Gelieve contract in PDF bij te voegen',
    amount_with_modalite_lib_invalid: '',
    bond_required: 'Aanvraag verplicht',
    bonds_management: 'Beheer van de borgstellingen',
    title_liberation: 'Beheer van de vrijgaven',
    your_ref_ext: 'Externe referentie van uw dossier',
    doc001: 'Contract',
    doc002: 'Specifieke tekst',
    doc003: 'Tijdelijke vereniging',
    doc200: 'Akte',
    doc201: 'Akte',
    doc202: 'Akte',
    doc900: 'Debetbericht',
    doc901: 'Debetbericht',
    doc902: 'Debetbericht',
    doc300: 'Vrijgave',
    doc301: 'Vrijgave',
    doc302: 'Vrijgave',
    type_needed: 'Type is noodzakelijk',
    for_momentary: 'Is de aanvraag voor een Tijdelijke Vereninging',
    company_form:
      'Gelieve formulier van de Tijdelijke Vereniging bij te voegen',
    company_form_required: 'Formulier Tijdelijke Vereniging is noodzakelijk',
    texte_special_doc: 'Gelieve document van de specifieke tekst bij te voegen',
    texte_special_doc_required: 'Document met specifieke tekst is noodzakelijk',
    current_company_form: 'Document van de Tijdelijke Vereniging',
    current_texte_special_form: 'Document  specifieke tekst',
    update_that_document: 'Een nieuw document kiezen',
    linked_documents: 'Link document',
    add_line: 'Een lijn toevoegen',
    success_multiple_liberations: 'Vrijgaven werden uitgevoerd',
    before: 'Voor',
    after: 'Na',
    amount_to_liberate: 'Bedrag van de vrijgave',
    nature_travaux_required: 'Beschrijving van de werken is noodzakelijk',
    nature_travaux_too_long: 'Beschrijving van de werken is te lang',
    all_in_one: 'Volledige vrijgave  (100%)',
    partial_50_50: 'Gedeeeltelijke vrijgave (50% / 50%)',
    search_for_a_company: 'Een onderneming opzoeken',
    number_: 'Nummer',
    total: 'Totaal',
    reset: 'Reset',
    manage_beneficiaires: 'Beheer begunstigden',
    private: 'Privaat',
    public: 'Publiek',
    list_number: 'Lijst',
    date_not_in_trimester: 'De datum valt niet in het huidige kwartaal',
    vat_number_invalid: 'BTW nummer is niet geldig',
    bank_account_invalid: 'Het rekeningnummer is niet geldig voor België',
    new_momentary_company: 'Oprichting van een tijdelijk bedrijf',
    confirm_edoc_delete: 'Wenst u deze document schrappen ?',
    nr_acte_required: 'Het actnummer is verplicht',
    nr_acte_too_long: 'Het actnummer is te lang',
    formalize_acte: 'Formaliseer het act',
    formalize: 'Formaliseer',
    nr_acte_not_equal_caut:
      'Het actnummer is niet gelijk aan het nummer van de borgstelling',
    this_is_complementary: 'Dit is een extra borgstelling',
    parent_bond: 'Relatief borgstelling',
    prefill_nature_travaux: 'Voorgevulde aard van de werken',
    caut_not_found: 'Borgstelling niet gevonden.',
    no_benef_found: 'Geen begunstigde verbonden',
    filter_by_name: 'Filter op naam',
    siege_social: 'Hoofdkantoor',
    correspondance: 'Correspondentie',
    info_required: 'Vereiste informatie',
    nr_amo: 'Associeer nummer',
    nr_amo_will_be_generated: 'Associeer nummer wordt automatisch gegenereerd',
    search_in_pdf: 'Zoeken in PDF',
    pdf_search_results_for: 'Zoekresultaten in PDF voor ',
    no_pdf_search_results_for: 'Geen zoekresultaten in PDF voor ',
    solde: 'Saldo',
    initial: 'initiaal',
    ref_ext: 'Ext.Réf',
    tooltip_open_request: 'Wijzigbaar aanvrag',
    tooltip_closed_request: 'Vergrendeld aanvrag',
    num: 'Num.',
    nr_id: 'Nationaal of ondernemingsregisternummer',
    nr_id_required: 'Nationaal of ondernemingsregisternummer is verplicht',
    nr_id_invalid: 'Ongeldig rijks- of ondernemingsregisternummer',
    nr_id_mandatory: 'Nationaal of bedrijfsregisternummer vereist',
    file_here: 'De bestanden die u uploadt, worden hier weergegeven',
    tooltip_action_dl_pdf:
      'Download de documenten met betrekking tot deze aanvraag',
    bond_id_since_when_you_want_documents:
      'Nummer van het verzoek waarvan u de documenten wenst op te vragen',
    download_document: 'Documenten downloaden',
    limit_id_for_edoc_dl: 'Maximum aantal aanvragen'
  }
}

export default translations
