<template>
  <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on }">
        <v-btn color="primary" v-on="on" class="mb-2">{{ $t('new_momentary_company') }}</v-btn>
      </template>

      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
          >
          {{ $t('new_momentary_company') }}
        </v-card-title>
        <CompanyForm
          :form="form"
          :isLoading="isLoading"
          :momentaryModal="true"
          :isUpdating="false"
          @validate="validate"
          :selectedCompany="selectedCompany"
          />
      </v-card>
    </v-dialog>
</template>

<script>
import CompanyForm from '../SuperAdmin/CompanyForm'
export default {
  components: { CompanyForm },
  props: ['selectedCompany'],
  data () {
    return {
      dialog: null,
      form: {
        name: '',
        language: '',
        vat_number: '',
        bank_account: '',
        is_momentary: true,
        linked_companies: [],
        valid: false
      },
      isLoading: false,
      snackbar: false
    }
  },
  computed: {
  },
  methods: {
    validate () {
      if (this.form.valid) {
        const payload = {
          ...this.form
        }
        this.isLoading = true
        this.$store.dispatch('createCompany', payload)
          .then((response) => {
            this.isLoading = false
            this.$emit('updateCompany', response.data.hash)
            this.dialog = false
          })
          .catch(() => {
            this.isLoading = false
            this.snackbar = true
          })
      }
    }
  },
  watch: {
    selectedCompany (value) {
      this.form.linked_companies = []
      this.form.linked_companies.push(value)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
